import Mustache from "mustache";
import "./libs/infobubble";
import "./styles/styles.less";
import { translation } from "./localisation";
import floPreset from "./presets/flo";
import ecnPreset from "./presets/ecn";
import bchPreset from "./presets/bch";
import cePreset from "./presets/ce";
import "whatwg-fetch";

require("file-loader?name=[name].[ext]!../index.html");
require("file-loader?name=[name].[ext]!../index-flo-dev.html");

window.Flo = (function () {
    var config = {};

    var divId = "";
    var mapMarkers = {};
    var imagesPath = "https://saemspprodstationmap.z9.web.core.windows.net/network-icon/";
    var template = "";

    var divElement = null;
    var wrapperDiv = null;
    var wrapperDivId = "aemap-wrapper-div";
    var mapDiv = null;
    var filterId = "stationmap-filters";
    var filterClass = "filter-map";
    var mapId = "aemap-map";
    var filterDiv = null;
    var searchInput = null;
    var autocompleteResults = null;
    var pattern = /([a-zA-Z-\s]+)?[0-9]{3,}$/;
    var infoWindows = [];
    var previousZoom = 0;
    var previousAjaxRequest;

    // Google Maps API objects
    var map = null;
    var markers = [];

    var placesService = null;
    var autocompleteService = null;
    var autocompleteSessionToken = null;

    var markerTimeout = new Date().getTime() + 2 * 60 * 1000;

    //Clustering
    var small = { height: 45, width: 45, textSize: 12 };
    var medium = { height: 55, width: 55, textSize: 13 };
    var big = { height: 65, width: 65, textSize: 14 };

    var clusterStyles = {};

    function getStationMapSearchDiv() {
        var stationMapSearchDiv =
            '	<input type="text" id="stationmap-search-input" placeholder="' +
            translation("Find a station") +
            '" />' +
            '	<div id="stationmap-search-input-icon"></div>' +
            '	<div id="stationmap-search-autocomplete" class="pac-container"></div>';
        return stationMapSearchDiv;
    }

    function getStationMapSearchOptionsDiv() {
        var stationMapSearchOptionsDiv =
            '	<div id="stationmap-options-toggle">' +
            '		<span class="icon"></span>' +
            '		<span class="text">' +
            translation("Options") +
            "</span>" +
            "	</div>" +
            "" +
            '	<div id="stationmap-options-collapse">' +
            '		<div class="stationmap-option">' +
            '			<select id="stationmap-filter-types">' +
            '				<option value="0">' +
            translation("All types") +
            "</option>" +
            '				<option value="1">' +
            translation("Fast DC") +
            "</option>" +
            "			</select>" +
            "		</div>" +
            "		" +
            '		<div class="stationmap-option">' +
            '			<select id="stationmap-filter-networks">' +
            '				<option value="0">' +
            translation("All networks") +
            "</option>" +
            '				<option value="1">' +
            translation("FLO network") +
            "</option>" +
            "			</select>" +
            "		</div>" +
            "		" +
            '		<div class="stationmap-option">' +
            '			<select id="stationmap-filter-states">' +
            '				<option value="0">' +
            translation("All stations") +
            "</option>" +
            '				<option value="1">' +
            translation("Available") +
            "</option>" +
            "			</select>" +
            "		</div>" +
            "	</div>";

        return stationMapSearchOptionsDiv;
    }

    var gmapReady = function () {
        var markerSizeX = 40;
        var markerSizeY = 64;

        markerImageOffsets = {
            0: {
                size: new google.maps.Size(markerSizeX, markerSizeY),
                origin: new google.maps.Point(markerSizeX, 0),
                scaledSize: new google.maps.Size(4 * markerSizeX, 2 * markerSizeY)
            },
            1: {
                size: new google.maps.Size(markerSizeX, markerSizeY),
                origin: new google.maps.Point(3 * markerSizeX, 0),
                scaledSize: new google.maps.Size(4 * markerSizeX, 2 * markerSizeY)
            },
            2: {
                size: new google.maps.Size(markerSizeX, markerSizeY),
                origin: new google.maps.Point(2 * markerSizeX, 0),
                scaledSize: new google.maps.Size(4 * markerSizeX, 2 * markerSizeY)
            },
            3: {
                size: new google.maps.Size(markerSizeX, markerSizeY),
                origin: new google.maps.Point(0, 0),
                scaledSize: new google.maps.Size(4 * markerSizeX, 2 * markerSizeY)
            }
        };

        dcMarkerImageOffsets = {
            0: {
                size: new google.maps.Size(markerSizeX, markerSizeY),
                origin: new google.maps.Point(markerSizeX, markerSizeY),
                scaledSize: new google.maps.Size(4 * markerSizeX, 2 * markerSizeY)
            },
            1: {
                size: new google.maps.Size(markerSizeX, markerSizeY),
                origin: new google.maps.Point(3 * markerSizeX, markerSizeY),
                scaledSize: new google.maps.Size(4 * markerSizeX, 2 * markerSizeY)
            },
            2: {
                size: new google.maps.Size(markerSizeX, markerSizeY),
                origin: new google.maps.Point(2 * markerSizeX, markerSizeY),
                scaledSize: new google.maps.Size(4 * markerSizeX, 2 * markerSizeY)
            },
            3: {
                size: new google.maps.Size(markerSizeX, markerSizeY),
                origin: new google.maps.Point(0, markerSizeY),
                scaledSize: new google.maps.Size(4 * markerSizeX, 2 * markerSizeY)
            }
        };

        appendFilterAndMapDivs();

        map = new google.maps.Map(mapDiv, {
            center: new google.maps.LatLng(
                0.5 * (config.InitialMapBounds.NE.latitude + config.InitialMapBounds.SW.latitude),
                0.5 * (config.InitialMapBounds.NE.longitude + config.InitialMapBounds.SW.longitude)
            ),
            zoom: config.InitialMapBounds.Zoom,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: true,
            mapTypeControl: true,
            streetViewControl: false,
            draggable: true,
            disableDoubleClickZoom: false,
            scrollwheel: true,
            keyboardShortcuts: false,
            styles: [
                {
                    featureType: "all",
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            lightness: "35"
                        }
                    ]
                },
                {
                    featureType: "all",
                    elementType: "geometry.stroke",
                    stylers: [
                        {
                            lightness: "35"
                        }
                    ]
                }
            ]
        });

        map.fitBounds(
            new google.maps.LatLngBounds(
                new google.maps.LatLng(config.InitialMapBounds.SW.latitude, config.InitialMapBounds.SW.longitude),
                new google.maps.LatLng(config.InitialMapBounds.NE.latitude, config.InitialMapBounds.NE.longitude)
            )
        );
        previousZoom = map.getZoom();

        // This is needed to set the zoom after fitbounds,
        google.maps.event.addListener(map, "zoom_changed", function () {
            if (this.getZoom() < 3) {
                // Change max/min zoom here
                this.setZoom(3);
            }
        });

        // When user clicks on map, close any open infoWindows
        google.maps.event.addListener(map, "click", closeAllInfoWindows);

        initSearch();
        document
            .getElementById("stationmap-options-toggle")
            .addEventListener("click", function () {
                document
                    .getElementById("stationmap-options-toggle")
                    .classList.toggle("expanded");
                document
                    .getElementById("stationmap-options-collapse")
                    .classList.toggle("expanded");
            });

        if (config.RenderLegend) {
            initLegend();

            document
                .getElementById("stationmap-legend")
                .addEventListener("click", function () {
                    document
                        .getElementById("stationmap-legend")
                        .classList.toggle("expanded");
                });
        }

        Array.from(document.querySelectorAll("#stationmap-options select")).forEach(
            item =>
                item.addEventListener("change", function () {
                    getAllStationMarkers(false);
                })
        );

        // Do something only the first time the map is loaded
        google.maps.event.addListenerOnce(map, "idle", function () {
            getAllStationMarkers(false);

            google.maps.event.addListener(
                map,
                "bounds_changed",
                debounce(
                    function () {
                        //do not update if we stayed at a "world-view" zoom level.
                        if (this.getZoom() > 4 || previousZoom > 4) {
                            previousZoom = this.getZoom();
                            getAllStationMarkers(false);
                        }
                    },
                    700,
                    false
                )
            );
        });
    };

    function appendFilterAndMapDivs() {
        divElement = document.getElementById(divId);

        wrapperDiv = document.createElement("div");
        wrapperDiv.id = wrapperDivId;
        wrapperDiv.className = `map-${config.Network}`;
        divElement.appendChild(wrapperDiv);

        filterDiv = document.createElement("div");
        filterDiv.id = filterId;
        filterDiv.className = filterClass;
        wrapperDiv.appendChild(filterDiv);

        mapDiv = document.createElement("div");
        mapDiv.id = mapId;
        wrapperDiv.appendChild(mapDiv);
    }

    // Source: https://davidwalsh.name/javascript-debounce-function
    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    function abbreviationToNetworkId(abbreviation) {
        abbreviation = abbreviation.toUpperCase();
        switch (abbreviation) {
            case "FLO":
                return [1, 6];
            case "CE":
                return [2];
            case "ECN":
                return [3];
            case "CPI":
                return [4, 10];
            case "BCH":
                return [7, 8];
            case "GRL":
                return [9];
            default:
                return [];
        }
    }

    function networkIdToAbbreviation(networkId) {
        switch (networkId) {
            case 1:
            case 6:
                return "FLO";
            case 2:
                return "CE";
            case 3:
                return "ECN";
            case 4:
            case 10:
                return "CPI";
            case 7:
            case 8:
                return "BCH";
            case 9:
                return "GRL";
            default:
                return "Generic";
        }
    }

    function networkIdToName(networkId) {
        switch (networkId) {
            case 1://flo-ca
            case 6://flo-us
                return translation("FLO");
            case 2:
                return translation("Electric Circuit");
            case 3:
                return translation("eCharge Network");
            case 4:
            case 10:
                return translation("ChargePoint");
            case 7:
            case 8://bch-greenlots
                return translation("BC Hydro");
            case 9:
                return translation("Greenlots");
            default:
                return translation("Unknown Network");
        }
    }

    function connectorTypeToName(connectorType, language) {
        switch (connectorType) {
            case "CHADEMO":
                return translation("CHAdeMO");
            case "IEC_62196_T1":
                return translation("J1772");
            case "IEC_62196_T1_COMBO":
                return translation("SAE Combo");
            case "IEC_62196_T2_COMBO":
                return translation("Mennekes");
            case "DOMESTIC_B":
                return translation("Standard 120V socket");

            default:
                return translation("Unknown connector");
        }
    }

    var statusMapping = {
        "Unknown": 0,
        "Available": 1,
        "InUse": 2,
        "OutOfService": 3
    };

    var markerIcon = function (network, status, level) {
        let statusInt = statusMapping[status];
        let icon = level == "L3" ? dcMarkerImageOffsets[statusInt] : markerImageOffsets[statusInt];

        switch (network) {
            case 1:
            case 6:
                icon["url"] = mapMarkers.FLO;
                break;
            case 2:
                icon["url"] = mapMarkers.CE;
                break;
            case 3:
                icon["url"] = mapMarkers.ECN;
                break;
            case 7:
            case 8:
                icon["url"] = mapMarkers.BCH;
                break;
            default:
                icon["url"] = mapMarkers.Generic;
                break;
        }

        return icon;
    };

    function clusterSize(count) {
        if (count < 10) {
            return "small";
        } else if (count < 100) {
            return "medium";
        } else {
            return "large";
        }
    }

    function clusterIcon(count) {
        var size = clusterSize(count);

        return {
            scaledSize: new google.maps.Size(
                clusterStyles[size].width,
                clusterStyles[size].height
            ),
            anchor: new google.maps.Point(
                clusterStyles[size].width / 2,
                clusterStyles[size].height / 2
            ),
            url: clusterStyles[size].url
        };
    }

    function clusterLabel(count) {
        var size = clusterSize(count);

        return {
            color: clusterStyles[size].textColor,
            text: count.toString(),
            fontSize: clusterStyles[size].textSize + "px"
        };
    }

    // Wait until Google Maps has loaded to initialize
    var markerImageOffsets = {};
    var dcMarkerImageOffsets = {};

    function calculateZIndex(status, latitude) {
        var baseIndex = 0;
        if (typeof status !== 'undefined') {
            switch (status) {
                case 0:
                    // Yellow
                    baseIndex = 1;
                    break;
                case 1:
                    // Green
                    baseIndex = 3;
                    break;
                case 2:
                    // Blue
                    baseIndex = 2;
                    break;
                case 3:
                    // Grey
                    baseIndex = 0;
                    break;
            }
        }
        return (baseIndex * 200 - latitude) * 1000000;
    }

    function closeAllInfoWindows() {
        if (infoWindows.length > 0) {
            infoWindows.forEach(function (iW) {
                iW.close();
            });

            infoWindows = [];
        }
    }

    function getAllStationMarkers(fitBounds) {
        fitBounds = typeof fitBounds !== "undefined" ? fitBounds : false;

        if (map == null) return;

        var bounds = {
            SouthWest: {
                Latitude: map
                    .getBounds()
                    .getSouthWest()
                    .lat(),
                Longitude: map
                    .getBounds()
                    .getSouthWest()
                    .lng()
            },
            NorthEast: {
                Latitude: map
                    .getBounds()
                    .getNorthEast()
                    .lat(),
                Longitude: map
                    .getBounds()
                    .getNorthEast()
                    .lng()
            }
        };

        // Get the network abbreviation from the config object
        let networkAbbreviation = config.Network;

        // Get an array of all the possible networkIds for the given abbreviation
        let networkIds = abbreviationToNetworkId(networkAbbreviation);

        // Set the networkIds property of the filter object
        var filter = {
            networkIds: document.getElementById("stationmap-filter-networks").value == "1" ? networkIds : [],
            connectors: null,
            levels: document.getElementById("stationmap-filter-types").value == "1" ? [3] : [],
            rates: [],
            statuses: document.getElementById("stationmap-filter-states").value == "1" ? ["Available"] : [],
            minChargingSpeed: null,
            maxChargingSpeed: null
        };

        previousAjaxRequest = fetch(config.UrlStationMarkers, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify({
                zoomLevel: map.getZoom(),
                bounds: bounds,
                filter: filter
            })
        });

        previousAjaxRequest
            .then(resp => resp.json())
            .then(function (data) {
                var previousMarkerCount = markers.length;

                // Check if there is any L3 level station in the list of stations
                if (data.parks) {
                    data.parks.forEach(function (park) {
                        var l3Index = park.stations.findIndex(function (station) {
                            return station.level === "L3";
                        });
                        // If there is an L3 level station, move it to the first element of the stations list
                        if (l3Index !== -1) {
                            var l3Station = park.stations.splice(l3Index, 1)[0];
                            park.stations.unshift(l3Station);
                        }
                    });
                }

                if (data.parks) {
                    data.parks.forEach(function (park) {
                        var stationIds = park.stations.map(function (station) {
                            return station.id;
                        });

                        const initialValue = 99;
                        const maxStationInfo = park.stations.reduce((minStatus, currentStation) => getMinStatus(minStatus, currentStation, initialValue), { statusId: initialValue, status: "Unknown", levelId: 0,  level: "" });
                        if (maxStationInfo.statusId === initialValue) maxStationInfo.statusId = statusMapping.Unknown;

                        var zIndex = calculateZIndex(maxStationInfo.statusId, park.geoCoordinates.latitude);
                        var singleMarker = new google.maps.Marker({
                            position: new google.maps.LatLng(
                                park.geoCoordinates.latitude,
                                park.geoCoordinates.longitude
                            ),
                            title: park.name,
                            map: map,
                            icon: markerIcon(park.networkId, maxStationInfo.status, maxStationInfo.level),
                            optimized: true,
                            ids: stationIds,
                            Status: maxStationInfo.status,
                            Count: park.stations.length,
                            zIndex: zIndex
                        });
                        singleMarker.clickHandler = google.maps.event.addListener(
                            singleMarker,
                            "click",
                            stationMarkerClick
                        );
                        markers.push(singleMarker);
                    });
                }

                // Handle clusters
                if (data.clusters) {
                    data.clusters.forEach(function (cluster) {
                        var zIndex = calculateZIndex(1, cluster.geoCoordinates.latitude);
                        var mapCluster = new google.maps.Marker({
                            position: new google.maps.LatLng(
                                cluster.geoCoordinates.latitude,
                                cluster.geoCoordinates.longitude
                            ),
                            title: cluster.totalCount + " stations",
                            map: map,
                            icon: clusterIcon(cluster.totalCount),
                            label: clusterLabel(cluster.totalCount),
                            optimized: false,
                            ids: cluster.id,
                            Count: cluster.totalCount,
                            zIndex: zIndex
                        });
                        mapCluster.clickHandler = google.maps.event.addListener(
                            mapCluster,
                            "click",
                            function (event) {
                                closeAllInfoWindows();
                                var zoom = map.getZoom();
                                if (zoom <= 8) {
                                    map.setZoom(zoom + 3);
                                } else if (zoom <= 12) {
                                    map.setZoom(zoom + 2);
                                } else {
                                    map.setZoom(zoom + 1);
                                }
                                map.setCenter({
                                    lat: cluster.geoCoordinates.latitude,
                                    lng: cluster.geoCoordinates.longitude
                                });
                            }
                        );
                        markers.push(mapCluster);
                    });
                }

                markers.slice(0, previousMarkerCount).forEach(function (marker) {
                    marker.setMap(null);
                });
                markers.splice(0, previousMarkerCount);
            });
    }

    function getMinStatus(resultStation, currentStation, unknownReplacement = 99) {
        const currentStationStatusId = statusMapping[currentStation.status] === statusMapping.Unknown ? unknownReplacement : statusMapping[currentStation.status];
        if (resultStation.statusId > currentStationStatusId) {
            resultStation.statusId = currentStationStatusId;
            resultStation.status = currentStation.status;
        }

        const currentLevel = parseInt(currentStation.level.substring(1));
        if (resultStation.levelId < currentLevel) {
            resultStation.level = currentStation.level;
            resultStation.levelId = currentLevel;
        }

        return resultStation;
    }

    function templateInfoBubble(imagesPath) {
        return (
            '<div class="infobubble-content">' +
            '   <ul class="networks">' +
            "       <li>" +
            '           <div class="network">' +
            '               <img class="network-icon-{{park.abbreviation}}" src="' +
            imagesPath +
            'network-icon-{{park.abbreviation}}.png" )" />' +
            "               <!-- Do not" +
            "               remove this comment -->" +
            "               {{park.networkName}}" +
            "           </div>" +
            '           <ul class="sites">' +
            "               <li>" +
            '                   <div class="site">' +
            "                       <h4>{{park.name}}</h4>" +
            "                       <p>" +
            "                           {{park.address.address1}},<br />" +
            "                           {{#park.address.address2}}{{park.address.address2}},<br />{{/park.address.address2}}" +
            "                           {{park.address.city}} {{#park.address.province}}{{park.address.province}} {{/park.address.province}}{{park.address.postalCode}}<br />" +
            "                           {{park.address.country}}" +
            "                      <br> </p>" +
            "                            {{#park.localizedDescription}}" +
            "                            <p>" +
            "                                {{park.localizedDescription}}" +
            "                            </p>" +
            "                            {{/park.localizedDescription}}" +
            "                   </div>" +
            '                   <ul class="chargingStations">' +
            '                       {{#stations}}' +
            '                       <li>' +
            '                           <div class="chargingStation">' +
            '                               {{name}}' +
            '                               <a href="http://maps.google.com/maps?daddr={{../geoCoordinates.latitude}}+{{../geoCoordinates.longitude}}" target="_blank" class="directions"><span class="icon"></span></a>' +
            '                           </div>' +
            '                           <ul class="ports">' +
            '                               <li>' +
            '                               {{#connectors}}' +
            '                               {{#isFirst}}' +
            '                                   <div class="port-state-icon port-state-icon-{{park.abbreviation}} port-state{{#isDC}}-dc{{/isDC}}-{{status}}"></div>' +
            '                                   <span class="port-state">{{stateString}}</span>' +
            '                               {{/isFirst}}' +
            '                               {{/connectors}}' +
            '                                   <ul class="connectors">' +
            '                                   {{#connectors}}' +
            '                                       <li>' +
            '                                       {{#isFirst}}' +
            '                                           <div class="tariff">{{#localizedDescription}}{{localizedDescription}}{{/localizedDescription}}{{^localizedDescription}}{{{translation("Unknown pricing")}}}{{/localizedDescription}}</div>' +
            '                                       {{/isFirst}}' +
            '                                           <div class="connector">{{name}}{{#powerInKW}}({{powerInKW}} kW){{/powerInKW}}</div>' +
            '                                       </li>' +
            '                               {{/connectors}}' +
            '                                   </ul>' +
            '                               </li>' +
            '                           </ul>' +
            '                       </li>' +
            '                       {{/stations}}' +
            "                   </ul>" +
            "               </li>" +
            "           </ul>" +
            "       </li>" +
            "   </ul>" +
            "</div>"
        );
    }




    var infoWindowContent = function (allData) {
        // Create an object to store the park and station data
        let parkAndStations = {
            park: allData[0],
            stations: []
        };

        // Loop through all the station data
        allData.forEach(function (data) {
            var park = data;
            var stations = data.stations;

            // Add localizedDescription property to tariffs
            stations.forEach(function (station) {
                var tariff = station.tariff;
                if (config.language in tariff.description) {
                    tariff.localizedDescription = tariff.description[config.language];
                } else if ("en" in tariff.description) {
                    tariff.localizedDescription = tariff.description["en"];
                } else if (Object.keys(tariff.description).length > 0) {
                    tariff.localizedDescription = tariff.description[
                        Object.keys(tariff.description)[0]
                    ];
                } else {
                    tariff.localizedDescription = "Unknown pricing";
                }
            });

            // Add localizedDescription to Parks
            if (config.language in park.description) {
                park.localizedDescription = park.description[config.language];
            } else if ("en" in park.description) {
                park.localizedDescription = park.description["en"];
            } else if (Object.keys(park.description).length > 0) {
                park.localizedDescription = park.description[
                    Object.keys(park.description)[0]
                ];
            } else {
                park.localizedDescription = "";
            }

            stations.forEach(function (station) {
                station.connectors = station.connectors.map(function (connector, index) {
                    connector.name = connectorTypeToName(connector.type);
                    connector.power = connector.power;
                    if (connector.power) {
                        connector.powerInKW = connector.power / 1000;
                    }
                    // Add localizedDescription property to connector
                    if (station.tariff) {
                        connector.localizedDescription = station.tariff.localizedDescription;
                    }
                    // Add isFirst property to first connector
                    if (index === 0) {
                        connector.isFirst = true;
                    }
                    return connector;
                });

                station.stateString = {
                    Unknown: translation("Unknown"),
                    Available: translation("Available"),
                    InUse: translation("In Use"),
                    OutOfService: translation("Out of Service")
                }[station.status];

                // Add isDC property to stations
                station.isDC = station.connectors.some(function (connector) {
                    return connector.powerType == "DC";
                });

                // Add the current station to the parkAndStations.stations array
                parkAndStations.stations.push(station);
            });

            if (park.address.province === "USA") {
                park.address.province = "";
            }

            data.networkName = networkIdToName(data.networkId);

            data.abbreviation = networkIdToAbbreviation(data.networkId);
        });

        // Render the Mustache template using the parkAndStations object
        return Mustache.render(template, parkAndStations);
    };



    async function stationMarkerClick() {
        var marker = this;
        closeAllInfoWindows();
        var infoWindow = new InfoBubble({
            map: map,
            content: "<div class='infobubble-loading'><span></span></div>",
            maxWidth: 400,
            minWidth: 400,
            maxHeight: 800,
            shadowStyle: 0,
            padding: 0,
            backgroundColor: "#ffffff",
            borderRadius: config.InfoWindowBorderRadius,
            arrowSize: 25,
            borderWidth: 0,
            borderColor: "#293133",
            arrowPosition: 50,
            arrowStyle: 0,
            disableAnimation: true,
            hideCloseButton: true
        });
        infoWindow.open(map, marker);
        infoWindows.push(infoWindow);

        // Create an array to store all the station data
        let allStationData = [];

        for (let id of marker.ids) {
            let url = `${config.UrlStationsInfo}${id}`;
            let resp = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8"
                }
            });
            let data = await resp.json();
            allStationData.push(data);
        }

        if (infoWindow.isOpen()) {
            infoWindow.close();
            infoWindow.setContent(infoWindowContent(allStationData));
            infoWindow.open();
        }
    }


    var initSearch = function () {
        var searchDivElement = document.createElement("div");
        searchDivElement.id = "stationmap-search";
        searchDivElement.innerHTML = getStationMapSearchDiv();

        var searchOptionsDivElement = document.createElement("div");
        searchOptionsDivElement.id = "stationmap-options";
        searchOptionsDivElement.innerHTML = getStationMapSearchOptionsDiv();

        filterDiv.appendChild(searchDivElement);
        filterDiv.appendChild(searchOptionsDivElement);

        searchInput = document.getElementById("stationmap-search-input");
        autocompleteResults = document.getElementById(
            "stationmap-search-autocomplete"
        );

        placesService = new google.maps.places.PlacesService(map);
        autocompleteService = new google.maps.places.AutocompleteService();

        // setup check for autocomplete results
        searchInput.addEventListener("keyup", function (e) {

            if (e.which == 13) {
                // Enter
                if ($("#stationmap-search-autocomplete .pac-item-refresh.selected").length == 1) {
                    $("#stationmap-search-autocomplete .pac-item-refresh.selected").mousedown();
                } else {
                    $("#stationmap-search-autocomplete .pac-item-refresh:first-child").mousedown();
                }
                searchInput.blur();
            } else if (e.which == 40) {
                // Arrow down
                if ($("#stationmap-search-autocomplete .pac-item-refresh.selected").length == 1) {
                    var selected = $("#stationmap-search-autocomplete .pac-item-refresh.selected");
                    selected.removeClass("selected");
                    if (selected.is(":last-child")) {
                        selected = $("#stationmap-search-autocomplete .pac-item-refresh:first-child");
                    } else {
                        selected = selected.next();
                    }
                    selected.addClass("selected");
                } else {
                    $("#stationmap-search-autocomplete .pac-item-refresh").removeClass("selected");
                    $("#stationmap-search-autocomplete .pac-item-refresh:first-child").addClass("selected");
                }
            } else if (e.which == 38) {
                // Arrow up
                if ($("#stationmap-search-autocomplete .pac-item-refresh.selected").length == 1) {
                    var selected = $("#stationmap-search-autocomplete .pac-item-refresh.selected");
                    selected.removeClass("selected");
                    if (selected.is(":first-child")) {
                        selected = $("#stationmap-search-autocomplete .pac-item-refresh:last-child");
                    } else {
                        selected = selected.prev();
                    }
                    selected.addClass("selected");
                } else {
                    $("#stationmap-search-autocomplete .pac-item-refresh").removeClass("selected");
                    $("#stationmap-search-autocomplete .pac-item-refresh:first-child").addClass("selected");
                }
            } else {
                getAutoCompleteResults(searchInput.value);
            }
        });

        // Show / hide autocompleteResults when searchInput gets / loses focus
        searchInput.addEventListener("blur", function (event) {
            setTimeout(function () {
                autocompleteResults.style.display = "none";
            }, 100);
        });
        searchInput.focus(function () {
            setTimeout(function () {
                if (autocompleteResults.innerHTML != "") {
                    autocompleteResults.style.display = "show";
                }
            }, 100);
        });
    };

    function initLegend() {
        var legendHtml =
            '<div id="stationmap-legend-toggle">' +
            "        <span>?</span>" +
            "    </div>" +
            '    <div id="stationmap-legend-collapse">' +
            '        <div class="stationmap-legend-entry" id="stationmap-legend-available">' +
            translation("Available") +
            "        </div>" +
            '        <div class="stationmap-legend-entry" id="stationmap-legend-inuse">' +
            translation("In Use") +
            "        </div>" +
            '        <div class="stationmap-legend-entry" id="stationmap-legend-unknown">' +
            translation("Unknown") +
            "        </div>" +
            '        <div class="stationmap-legend-entry" id="stationmap-legend-outofservice">' +
            translation("Out of Service") +
            "        </div>" +
            '        <div class="stationmap-legend-entry" id="stationmap-legend-fastdc">' +
            translation("Fast DC") +
            "        </div>" +
            "    </div>";

        var legendDivElement = document.createElement("div");
        legendDivElement.id = "stationmap-legend";
        legendDivElement.innerHTML = legendHtml;

        wrapperDiv.appendChild(legendDivElement);
    }

    var getAutoCompleteResults = function (query) {
        if (query == "") {
            autocompleteResults.innerHTML = "";
            autocompleteResults.style.display = "none";
            return;
        }
        if (autocompleteSessionToken == null) {
            autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
        }

        autocompleteService.getPlacePredictions(
            {
                input: query,
                bounds: map.getBounds(),
                componentRestrictions: { country: ["ca", "us"] },
                sessionToken: autocompleteSessionToken
            },
            function (predictions, status) {
                autocompleteResults.innerHTML = "";

                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    for (var i = 0, prediction;
                        (prediction = predictions[i]); i++) {
                        if (prediction.terms.length == 0) {
                            continue;
                        }

                        var autocompleteEntry =
                            '<div class="pac-item-refresh row">' +
                            '<span class="pac-icon-container pac-icon-location"></span>' +
                            '<span class="pac-item-query" data-type="place" data-reference="' +
                            prediction.reference +
                            '">';

                        autocompleteEntry =
                            autocompleteEntry +
                            '<span class="pac-item-bold">' +
                            prediction.terms[0].value +
                            "</span>";

                        for (var j = 1, term;
                            (term = prediction.terms[j]); j++) {
                            autocompleteEntry = autocompleteEntry + ", " + term.value;
                        }

                        autocompleteEntry = autocompleteEntry + "</span>" + "</div>";

                        autocompleteResults.innerHTML += autocompleteEntry;
                    }

                    autocompleteResults.style.display = "block";

                    // Array.from(
                    //   document.querySelectorAll(
                    //     `#stationmap-search-autocomplete .pac-item-refresh`
                    //   )
                    // ).forEach(item => {
                    //   item.addEventListener("mousedown", goToAutocompleteOption);
                    // });
                    $('#stationmap-search-autocomplete').find(".pac-item-refresh").mousedown(goToAutocompleteOption);
                } else {
                    autocompleteResults.innerHTML = "";
                    autocompleteResults.style.display = "none";
                }
            }
        );
    };

    var goToAutocompleteOption = function (clickEvent) {
        window.test = clickEvent.currentTarget;
        var option = clickEvent.currentTarget.getElementsByClassName(
            "pac-item-query"
        )[0];

        var optionReference = option.dataset.reference;

        // get place from Google placesService
        placesService.getDetails({
            reference: optionReference,
            sessionToken: autocompleteSessionToken,
            fields: ['geometry']
        }, function (
            results,
            status
        ) {
            var place = results instanceof Array ? results[0] : results;

            if (status == google.maps.places.PlacesServiceStatus.OK) {
                searchInput.value = option.textContent;
                autocompleteResults.innerHtml = "";

                var filter = {
                    networkIds: document.getElementById("stationmap-filter-networks").value == "0" ? [] : [1],
                    connectors: null,
                    levels: document.getElementById("stationmap-filter-types").value == "1" ? [3] : [],
                    rates: [],
                    statuses: document.getElementById("stationmap-filter-states").value == "1" ? ["available"] : [],
                    minChargingSpeed: null,
                    maxChargingSpeed: null
                };

                fetch(config.UrlNearestStation, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8"
                    },
                    body: JSON.stringify({
                        geoCoordinates: {
                            latitude: place.geometry.location.lat(),
                            longitude: place.geometry.location.lng()
                        },
                        filter: filter
                    })
                })
                    .then(resp => resp.json())
                    .then(
                        function (data) {
                            var center = null;
                            if (place.geometry.viewport) {
                                center = place.geometry.viewport.getCenter();
                            } else {
                                center = place.geometry.location;
                            }

                            var pointA;
                            if (data.parks && data.parks.length > 0) {
                                var firstPark = data.parks[0];
                                var parkCoordinates = firstPark.geoCoordinates;
                                pointA = new google.maps.LatLng(parkCoordinates.latitude, parkCoordinates.longitude);
                            } else {
                                // Handle case where no parks are returned in the response data
                                map.setCenter(center);
                                map.setZoom(16);
                                return;
                            }

                            var pointB = place.geometry.viewport ?
                                place.geometry.viewport.getNorthEast() :
                                pointA;
                            var pointC = place.geometry.viewport ?
                                place.geometry.viewport.getSouthWest() :
                                pointA;

                            var latDistance = Math.max(
                                Math.abs(center.lat() - pointA.lat()),
                                Math.abs(center.lat() - pointB.lat()),
                                Math.abs(center.lat() - pointC.lat())
                            );
                            var lngDistance = Math.max(
                                Math.abs(center.lng() - pointA.lng()),
                                Math.abs(center.lng() - pointB.lng()),
                                Math.abs(center.lng() - pointC.lng())
                            );

                            var newBounds = new google.maps.LatLngBounds(
                                new google.maps.LatLng(
                                    center.lat() - latDistance,
                                    center.lng() - lngDistance
                                ),
                                new google.maps.LatLng(
                                    center.lat() + latDistance,
                                    center.lng() + lngDistance
                                )
                            );

                            map.setCenter(center);
                            map.fitBounds(newBounds);
                        },
                        function (error) {
                            map.setCenter(place.geometry.location);
                            if (place.geometry.viewport) {
                                map.fitBounds(place.geometry.viewport);
                            } else {
                                map.setZoom(16); // Why 17? Because it looks good.
                            }
                        }
                    );
            }
        });
        autocompleteSessionToken = null;
        //hideAllOverlays();
    };

    function setClusterStyles(mapCluster) {
        clusterStyles = {

            small: {
                url: mapCluster.Green.Small,
                height: small.height,
                width: small.width,
                textColor: "#FFFFFF",
                textSize: small.textSize
            },
            medium: {
                url: mapCluster.Green.Medium,
                height: medium.height,
                width: medium.width,
                textColor: "#FFFFFF",
                textSize: medium.textSize
            },
            large: {
                url: mapCluster.Green.Large,
                height: big.height,
                width: big.width,
                textColor: "#FFFFFF",
                textSize: big.textSize
            }
        };
    }

    function Includes(gMapsKey) {
        var googleMapsUrl =
            "https://maps.googleapis.com/maps/api/js?callback=Flo.gmapReady&libraries=places";
        if (gMapsKey != null || gMapsKey != "") {
            googleMapsUrl = googleMapsUrl + "&key=" + gMapsKey;
        }
        includeJs(googleMapsUrl);
    }

    function includeJs(jsFilePath) {
        var js = document.createElement("script");

        js.type = "text/javascript";
        js.src = jsFilePath;

        document.getElementsByTagName("head")[0].appendChild(js);
    }

    return {
        initMap: function (id, options = {
            preset: "flo",
            language: "en"
        }) {
            switch (options.preset) {
                case "ce":
                    config = {
                        ...cePreset,
                        ...options
                    };
                    break;
                case "ecn":
                    config = {
                        ...ecnPreset,
                        ...options
                    };
                    break;
                case "bch":
                    config = {
                        ...bchPreset,
                        ...options
                    };
                    break;
                case "flo":
                default:
                    config = {
                        ...floPreset,
                        ...options
                    };
            }

            translation.setLanguage(config.language);
            translation.setTranslations(config.Translations);
            divId = id;
            mapMarkers = config.MapMarkers;
            template = templateInfoBubble(imagesPath);
            Includes(config.GoogleMapsApiKey);
            setClusterStyles(config.MapCluster);
        },
        gmapReady: gmapReady
    };
})();